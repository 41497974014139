/**
 * Interface for interacting with the pendo api. This wrapper will
 * handle identification prefix event names topically (action, link, etc)
 * to identify them more easily. Methods here all fail silently and are
 * ignored (not even logged.) in order to not disturb the SR app at all.
 */

window.METRIC_LOG = false

var MetricManager = function () {
  var that = this

  that.pendo = window.pendo
  that.identified = false

  that.id = null
  that.email = null

  that.venue_id = null
  that.venue_name = null
  that.venue_key = null

  // Prefix name of event to distinguish it as a front end event rather
  // than events tracked on mobile or on the server side.
  that.event_prefix = 'FE.'

  that.log_style = 'background: #222; color: #bada55'

  /**
   * Special logger for pendo events.
   */
  that.log = function (msg) {
    if (!window.METRIC_LOG) {
      return
    }
    console.log('%cMetric: ' + '%c' + msg, that.log_style, that.log_style)
  }

  /**
   * Point the mp attribute to the the global pendo object
   */
  that.init = function () {
    try {
      that.pendo = window.pendo
    } catch (err) {
      that.log(err)
      that.log('Could not initialize pendo analytics manager. Events will not be sent')
    }
  }

  that.getVenueInfo = function () {
    var venue_key = Pmp.Manager.Global._url_key_or_id

    return {
      name: '',
      id: '',
      url_key: venue_key,
    }
  }

  that.getId = function () {
    try {
      var email = Pmp.Manager.Global._user_email
    } catch (err) {
      that.log(err)
      that.log('Could not get user(id) info for this session')
      var email = ''
    }
    return email
  }

  /**
   * Get email of the logged in user and use it to identify the profile of
   * the user.
   */
  that.identify = function () {
    try {
      that.init()
      if (!that.identified) {
        that.id = that.getId()

        var venue_info = that.getVenueInfo()
        that.venue_key = venue_info['url_key']
        that.pendo.initialize({
          visitor: {
            id: that.id,
            email: that.id,
            first_name: Pmp.Manager.Global._user_first_name,
            last_name: Pmp.Manager.Global._user_last_name,
            job_title: Pmp.Manager.Global._job_title,
            user_create_date: Pmp.Manager.Global._user_create_date,
            access_level: Pmp.Manager.Global._privilege_level,
            additional_options: Pmp.Manager.Global._user_additional_options,
          },
          account: {
            id: that.venue_key,
            unique_url: that.venue_key,
            access_orgs: Pmp.Manager.Global._access_orgs,
            venue_squid: Pmp.Manager.Global._venue_squid,
            venue_class: Pmp.Manager.Global._venue_class,
            venue_location: Pmp.Manager.Global._venue_location,
            venue_has_floorplan: Pmp.Manager.Global._venue_has_floorplan,
            account_create_date: Pmp.Manager.Global._account_create_date,
            guestlist_enabled: Pmp.Manager.Global._guestlist_enabled,
            event_widget_enabled: Pmp.Manager.Global._event_widget_enabled,
            dining_widget_enabled: Pmp.Manager.Global._dining_widget_enabled,
            membership_enabled: Pmp.Manager.Global._membership_enabled,
            sizzle_enabled: Pmp.Manager.Global._sizzle_enabled,
            guest_satisfaction_enabled: Pmp.Manager.Global._guest_satisfaction_enabled,
            payments_enabled: Pmp.Manager.Global._payments_enabled,
            gc_sync_method: Pmp.Manager.Global._gc_sync_method,
            env: Pmp.Manager.Global._env,
            parent_id: Pmp.Manager.Global._venue_group_id,
            parent_name: Pmp.Manager.Global._venue_group_name,
            parent_squid: Pmp.Manager.Global._venue_group_squid,
            orders_enabled: Pmp.Manager.Global._is_orders_integration_enabled,
            order_systems: Pmp.Manager.Global._order_systems,
            product_provision_package: Pmp.Manager.Global._productProvisionPackage,
            product_provision_addon_online_ordering: Pmp.Manager.Global._productProvisionAddonOnlineOrdering,
            product_provision_addon_email_campaigns: Pmp.Manager.Global._productProvisionAddonEmailCampaigns,
            product_provision_addon_api: Pmp.Manager.Global._productProvisionAddonApi,
            product_provision_addon_premium_integrations_array: Pmp.Manager.Global._productProvisionAddonPremiumIntegrations,
            product_provision_addon_pms: Pmp.Manager.Global._productProvisionAddonPms,
            product_provision_addon_sms_marketing: Pmp.Manager.Global._productProvisionAddonSmsMarketing,
            insight_new_count: globalInit.newInsightCount,
            highlight_insight_text: Pmp.Manager.Global._highlightInsightText,
            highlight_insight_type_key: Pmp.Manager.Global._highlightInsightTypeKey,
            highlight_insight_link: Pmp.Manager.Global._highlightInsightLink,
            force_redirects_on_legacy_widget: Pmp.Manager.Global._forceRedirectsOnLegacyWidget,
            new_res_widget_enabled: Pmp.Manager.Global._newResWidgetEnabled,
            internal_ar_booking_enabled: Pmp.Manager.Global._internalArBookingEnabled,
            internal_ar_booking_enabled_mobile: Pmp.Manager.Global._internalArBookingEnabledMobile,
            internal_upsells_enabled: Pmp.Manager.Global._internalUpsellsEnabled,
            internal_upsells_enabled_mobile: Pmp.Manager.Global._internalUpsellsEnabledMobile,
          },
        })
        that.identified = true
      }
    } catch (err) {
      that.log(err)
      that.log('Could not identify this user')
    }
  }

  /**
   * Set properties of the profile such as name, last login date, etc.
   * Most of this information is recorded during events that are tracked
   * server side (like logins or reservations).
   */
  that.setProfile = function (props) {
    try {
      props = props || {}
      that.init()
      if (!that.identified) {
        that.identify()
      }
      props['$id'] = that.id
      props['$email'] = that.id
      props['$venue_key'] = that.venue_key
    } catch (err) {
      that.log(err)
      that.log('Could not set profile for this user')
    }
  }

  /**
   * Track an event after identifying the current user. This is the main
   * function to use.
   */
  that.track = function (event_name, props, callback) {
    try {
      if (!that.identified) {
        that.identify()
        that.setProfile()
      }

      that.pendo.track(that.event_prefix + event_name, props)
    } catch (err) {
      that.log(err)
      that.log('Error trying to send track event')
    }
  }

  // Remove all the track_link related code after we are fully switched to Pendo.
  // See https://sevenrooms.atlassian.net/browse/VX-75 (limiting Mixpanel Events)

  /**
   * Track a link click by providing a selector for the <a> tag. The DOM
   * element must be in DOM at the time of calling this function.
   */
  that.track_link = function (selector, event_name) {
    try {
      if (!that.identified) {
        that.identify()
        that.setProfile()
      }
      if (event_name === 'nav.logout') {
        that.log('Tracking event, ' + event_name + ' on ' + selector)
      }
    } catch (err) {
      that.log(err)
      that.log('Error trying to set up tracking link')
    }
  }

  /**
   * Track as many link clicks as we can.
   */
  that.track_links = function () {
    // Main navigation icon links
    that.track_link('#reservations-link a', 'Nav.reservations')
    that.track_link('#grid-link a', 'Nav.grid')
    that.track_link('#guestlist-link a', 'Nav.guestlist')
    that.track_link('#requests-link a', 'Nav.requests')
    that.track_link('#floorplan-link a', 'Nav.floorplan')
    that.track_link('#clients-link a', 'Nav.clients')
    that.track_link('#reports-link a', 'Nav.reporting')
    that.track_link('#marketing-link a', 'Nav.marketing')
    that.track_link('#settings-link', 'Nav.settings')
    that.track_link('#helpdesk-link', 'Nav.help')
    that.track_link('#logo a', 'Nav.logo')

    // Top right options links, like settings, logout, etc
    that.track_link('#account-link', 'nav.account')
    that.track_link('#logout-link', 'nav.logout')

    // settings page - general
    that.track_link('#settings-link-general-new', 'Settings.general')
    that.track_link('#settings-link-general-tags', 'Settings.tags')
    that.track_link('#settings-link-general-membership-types', 'Settings.membershipTypes')
    that.track_link('#settings-link-general-payouts', 'Settings.payouts')
    that.track_link('#settings-pos', 'Settings.POS')

    // settings page - People
    that.track_link('#settings-link-people-user-accounts', 'Settings.userAccounts')
    that.track_link('#settings-link-people-bbn', 'Settings.bookedByNames')
    that.track_link('#settings-link-people-server-names', 'Settings.serverNames')
    that.track_link('#settings-link-people-tpa', 'Settings.thirdPartyAccess')

    // settings page - Floor plan
    that.track_link('#settings-link-floor-rooms', 'Settings.rooms')
    that.track_link('#settings-link-floor-seating-areas', 'Settings.seatingAreas')
    that.track_link('#settings-link-floor-tables', 'Settings.tables')
    that.track_link('#settings-link-floor-bar', 'Settings.bar')
    that.track_link('#settings-link-floor-statuses', 'Settings.reservationStatuses')

    // settings page - availability
    that.track_link('#settings-link-schedules', 'Settings.shifts')
    that.track_link('#settings-link-access', 'Settings.accessRules')
    that.track_link('#settings-link-avail-widget-settings', 'Settings.widgetSettings')
    that.track_link('#settings-link-avail-reservation-access', 'Settings.reservationAccess')
    that.track_link('#settings-link-avail-booking-rules', 'Settings.bookingRules')
    that.track_link('#settings-link-avail-events', 'Settings.weekly_events')
    that.track_link('#settings-link-avail-blackout', 'Settings.blackoutDate')
    that.track_link('#settings-link-avail-guestlist', 'Settings.third_partyGuestlist')
    that.track_link('#settings-link-avail-perks', 'Settings.perks')

    // reports links
    that.track_link('#reports-link-summary', 'Reports.summary')
    that.track_link('#reports-link-booked_by', 'Reports.bookedBy')
    that.track_link('#reports-link-guestlist', 'Reports.guestlist')
    that.track_link('#reports-link-tally', 'Reports.tally')
    that.track_link('#reports-link-actuals', 'Reports.actuals')
    that.track_link('#reports-link-historical', 'Reports.historicalReservations')
    that.track_link('#reports-link-payments', 'Reports.payments')

    // client links
    that.track_link('#import-clients', 'Clients.import')
    that.track_link('#create-new-profile-btn', 'Clients.addNew')

    // guestlist
    that.track_link('#book-many-btn', 'Guestlist.bookMany')
    that.track_link('#book-guest', 'Guestlist.addGuest')
    that.track_link('.guestlist-print-link-btn', 'Guestlist.print')
    that.track_link('.guestlist-export-link-btn', 'Guestlist.export')

    // Reservations
    that.track_link('.reservations-print-link-btn', 'Reservations.print')
    that.track_link('.reservations-export-link-btn', 'Reservations.export')

    // Report exports
    that.track_link('.report-export-link-btn', 'Reports.export')

    // General exports
    // all export links have the same id, this will catch any export links that
    // are not explicity caught in the list above
    // that.track_link('#export-link', 'Export');
  }
}

metric = new MetricManager()
window.metricManager = metric
$(document).ready(function () {
  metric.track_links()
})
