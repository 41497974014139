/* Always enable console logging */
var console = console || {};
console.log = console.log || function(msg){};

/* Global Settings (overridden in main.soy) */

var Pmp = Pmp || {};
Pmp.Settings = Pmp.Settings || {};

Pmp.Settings.DEBUG = false;
Pmp.Settings.IS_PRODUCTION = false;
Pmp.Settings.IS_MOBILE = false;
Pmp.Settings.MOBILE_PATH = '/m';
Pmp.Settings.MEDIA_URL = 'undefined';
Pmp.Settings.LOAD_TEMPLATE = false;
