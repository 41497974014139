/*
	Google Closure Template hacking
	Requires: jquery 1.4.2, soyutils.js
	Copyright 2010 PMP Partners, LLC
*/

/*
	Namespace declaration
*/
var Closure = Closure || {};
Closure.Util = Closure.Util || {};

/*
	Insert a dynamic template
*/
Closure.Util.InsertTemplate = function (opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  if (opt_data && opt_data.DynamicTemplate) {
    try {
      var templateFunc = eval(opt_data.DynamicTemplate);
    } catch (e) {
      throw ("Cannot find template " + opt_data.DynamicTemplate);
    }
    try {
      templateFunc(opt_data, opt_sb);
    } catch (e) {
      throw ("Error when executing Dynamic Template " + opt_data.DynamicTemplate + ": " + e.toString());
    }
  }
  if (!opt_sb) return output.toString();
};
