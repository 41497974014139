
var Pmp = Pmp || {};
Pmp.Utils = Pmp.Utils || {};
Pmp.Utils.Currency = Pmp.Utils.Currency || {};

Pmp.Utils.Currency.AddSymbolToElementVal = function(element, currencySymbol, addCommas) {
	var value = $(element).val();
	value = Pmp.Utils.Currency.AddSymbolToVal(value, currencySymbol, addCommas);
	$(element).val(value);
}

Pmp.Utils.Currency.AddSymbolToVal = function(value, currencySymbol, addCommas) {
	var result = value.toString();
	re_dollar_sign = new RegExp("["+ currencySymbol +"]", "ig");
	if (result.match(re_dollar_sign) === null) {
		result = currencySymbol + result;
	}
	if (addCommas) {
		return Pmp.Utils.Currency.NumberWithCommas(result);
	}
	return result;
}

Pmp.Utils.Currency.NumberWithCommas = function(x) {
	/*
	Note that this is not locale sensitive. There is a wonderful function
	called .toLocaleString() that works in every browser except Safari. In the absence
	of that simplicity this will require a third party library and is work for
	another day. -KP 4/12/16
	*/
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

Pmp.Utils.Currency.StripSymbol = function(value, currency_symbol) {
	re_dollar_sign = new RegExp("["+ currency_symbol +"]", "ig");
	return value.replace(re_dollar_sign, '');
}

Pmp.Utils.Currency.RawDecimal = function(value, currency_symbol) {
	modified_value = value;
	if (currency_symbol != undefined) {
		modified_value = Pmp.Utils.Currency.StripSymbol(value, currency_symbol);
	}

	return modified_value.replace(/[,\$\xa3\xdb\s]/ig, ''); // strip $, gbp, euro, and whitespace
};
