/** 
 * CustomerSuccess Data Tracker 
 */

var CustomerSuccessTracker = CustomerSuccessTracker || {};

CustomerSuccessTracker.setAccountAttributes = function(account_attributes) {
    var attribute_params = CustomerSuccessTracker.generateAttributeParams(account_attributes);
    window.totango_options = window.totango_options || {};
    window.totango_options.account = _.assign({}, window.totango_options.account, attribute_params);
    totango.go(window.totango_options);
    totango.track();
};

CustomerSuccessTracker.trackAccountModule = function(moduleName, action) {
    totango.track(action, moduleName);
}

CustomerSuccessTracker.generateAttributeParams = function(account_attributes) {
    var attribute_params = {}
    if ('access_rules' in account_attributes) {
        attribute_params['Access Rules'] = account_attributes.access_rules ? 'True' : 'False'
    }
    if ('description_active' in account_attributes) {
        attribute_params['Public Description Active'] = account_attributes.description_active ? 'True' : 'False'
        if (account_attributes.description_active) {
            attribute_params['Public Description Ever Used'] = 'True'
        }
    }
    if ('perks_and_upgrades' in account_attributes) {
        attribute_params['Perks & Upgrades Active'] = account_attributes.perks_and_upgrades ? 'True' : 'False'
        if (account_attributes.perks_and_upgrades) {
            attribute_params['Perks & Upgrades Ever Used'] = 'True'
        }
    }
    if ('time_slot_description' in account_attributes && account_attributes.time_slot_description) {
        attribute_params['Timeslot Description Ever Used'] = 'True'
    }
    if ('audiences' in account_attributes) {
        attribute_params['Number of Audiences Active'] = account_attributes.audiences
    }
    if ('daily_programme' in account_attributes ) {
        attribute_params['Daily Programme'] = account_attributes.daily_programme ? 'True' : 'False'
    }

    return attribute_params
}

CustomerSuccessTracker.trackAvailability = function(action) {
    CustomerSuccessTracker.trackAccountModule('Availability', action);
}

CustomerSuccessTracker.trackRequestInbox = function(action) {
    CustomerSuccessTracker.trackAccountModule('Request Inbox', action);
}

CustomerSuccessTracker.trackCRM = function(action) {
    CustomerSuccessTracker.trackAccountModule('CRM', action);
}

CustomerSuccessTracker.trackReporting = function(action) {
    CustomerSuccessTracker.trackAccountModule('Reporting', action);
}