var sevenrooms = sevenrooms || {};
sevenrooms.widgetdefaults = {

  is24HourClock: false, 

  holdTime: 25,
  arrivalWait: 30,
  
  font: 'Roboto',
  requestOnly: false,
  disableRequests: false,
  maxFutureDays: 0,

  // Slim is incompatible with fullscreen. McFly.
  windowDisplay: 'full',

  backgroundColor: '#000000',
  backgroundImage: '',
  logoImage: '',

  // Colors in summary and success depend on fontColor;
  // allowed for more granular control over the big
  // menu stuff.
  fontColor: '#FFFFFF',
  linkColor: '#FFFFFF',
  placeHolderText: 'gray',

  backgroundOverlay: '#000000',
  backgroundOverlayOpacity: '0.8',

  buttonFontColor: '#FFFFFF',
  buttonBackgroundColor: '#414141',
  buttonActiveColor: '#717171',

  dropdownFontColor: '#000000',
  dropdownBackgroundColor: '#FFFFFF',

  dowFontColor: '#FFFFFF',
  dowBackgroundColor: '#505050',
  
  headerFontColor: '#FFFFFF',
  headerBorderColor: '#FFFFFF',
  headerBackgroundColor: '#000000',

  optionFontColor: '#000000',
  optionBackgroundColor: '#FFFFFF',
  optionButtonFontColor: '#FFFFFF',
  optionButtonBackgroundColor: '#000000',

  submitButtonText: '#000000',
  submitButtonBackground: '#FFFFFF',

  errorFormColor: '#CC0000',
  errorBannerBackgroundColor: '#D01A21',
  errorBannerFontColor: '#FFFFFF',

  countdownColor: '#AAAAAA',

  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',

  january_abbr: 'Jan',
  february_abbr: 'Feb',
  march_abbr: 'Mar',
  april_abbr: 'Apr',
  may_abbr: 'May',
  june_abbr: 'Jun',
  july_abbr: 'Jul',
  august_abbr: 'Aug',
  september_abbr: 'Sep',
  october_abbr: 'Oct',
  november_abbr: 'Nov',
  december_abbr: 'Dec',

  sun: 'sun',        
  mon: 'mon',        
  tue: 'tue',        
  wed: 'wed',        
  thu: 'thu',        
  fri: 'fri',        
  sat: 'sat',        

  guests: 'Guests',
  guest: 'Guest',
  date: 'Date',
  book: 'book',
  request: 'request',
  reservation: 'reservation',
  request_details: 'request details',
  reservation_details: 'reservation details',
  ran_out_of_time: 'oh no! we ran out of time',
  holding_for: 'holding for',
  first_name: 'first',
  last_name: 'last',
  email: 'email',
  phone: 'phone',
  message: 'message',
  submit: 'submit',
  flexible_date: 'flexible date',
  i_agree_to: 'I agree to',
  by_clicking: 'By clicking "submit" you agree to SevenRooms\'',
  terms_and_conditions: 'Terms and Conditions',
  and: 'and',
  privacy_policy: 'Privacy Policy',
  view_terms: 'view terms',
  custom_terms: 'terms',
  close: 'close',

  its_official: "it's official",
  on_the_books: "you're on the books",
  start_new: "start a new reservation",
  try_again: "try again",
  per_person: "per person",
  per_reservation: "per reservation",

  no_first_name: "First name required",
  no_email: "Please enter an email",
  invalid_email: "Invalid email",
  no_phone: "Phone required",
  no_terms: "You must agree to the terms",
  add_to_calendar: "add to calendar",

  require_credit_card: false,
  credit_card_required: 'credit card required',
  card_nag: 'credit card required to book this reservation',
  payment_nag: 'payment required to book this reservation',
  continue_to_card: 'continue to credit card details',
  card_name: 'card holder\'s name',
  card_number: 'card number',
  card_cvv: 'cvv',
  
  no_card_name: 'Name required',
  no_card_number: 'Number required',
  no_card_exp: 'Expiration required',
  no_card_cvv: 'CVV required',

  charge_subtotal: 'subtotal',
  charge_total: 'total',
  charge_tip: 'gratuity',
  charge_tax: 'tax',

  no_availability: 'There is no availability for this day',
  confirmation_sent_to: 'confirmation and details sent to',
  update_or_cancel: "if you need to update or cancel your reservation or have any questions, please call the restaurant at",
  give_up_table: "if you do not arrive within TIME minutes, we will have to give up your table",
  dont_worry: "Don't worry! We'll save your information and notes for when you return to the check out",
  whoops: "Whoops! Something went wrong. Try again in a few minutes",
  card_declined: "The credit card entered was declined. Please update your credit card information.",
  card_expired: "The credit card entered is expired. Please update your credit card information.",
  process_error: "We encountered a processing error. Try again in a few minutes.",
  already_booked: "You already have a reservation for this time",
  slot_taken: "We've encountered an availability issue. Please select a different time.",

  // Request alt language
  request_sent: 'your request has been sent',
  a_member: 'a member of our team will be in touch soon!',
  look_out_for_email: 'Look out for an email from us at',
  got_question: 'If you have any questions or need to update your request, please contact us at'
};

