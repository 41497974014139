/*

TODO: make the concept of a page extendable such that pages can be nested in pages.
      this will require that the event handlers are linked to page objects, not static
      factories.  however, it will allow for very robust ajax driven widgets with
      minimal overhead in developer time... worth playing with post 1.0

*/


/*
  Client-side Javascript Framework
  Requires: jquery 1.4.2, jquery address 1.2.2
  Copyright 2010 PMP Partners, LLC
*/

/*
  Namespace declaration
*/

var Pmp = Pmp || {};
Pmp.Client = Pmp.Client || {};
Pmp.Client.Options = Pmp.Client.Options || {};
Pmp.Client.Static = Pmp.Client.Static || {};
Pmp.Client.Factory = Pmp.Client.Factory || {};
Pmp.Client.Page = Pmp.Client.Page || {};
Pmp.Client.Utils = Pmp.Client.Utils || {};

/*
  Public settings/options
  *CHANGE AS NECESSARY*
*/
Pmp.Client.Options.AjaxClassName = "ajaxify"; // must be globally unique
Pmp.Client.Options.AjaxUrlPrefix = "/a/#/"; // must begin and end with a slash
Pmp.Client.Options.DoAjaxify = false;

/*
  Public Data / objects
  *DO NOT MODIFY*
*/
Pmp.Client.Static.Page = null; //globally accessible page object
Pmp.Client.Static.Factory = null; // globally accessible factory, initialized at bottom
Pmp.Client.Static.AddressChangeCount = 0; // stores number of renders for this ajax session
Pmp.Client.Static.EnableAddressHandling = true;
Pmp.Client.Static.LiveEventHandlers = {};
Pmp.Client.Static.PageTracker = null;
Pmp.Client.Static.SiteVersion = null;
Pmp.Client.Static.PendingRequests = [];
Pmp.Client.Static.JsTemplatesLoaded = {};
Pmp.Client.Static.TemplateDir = 'js/templates/';
Pmp.Client.Static.JsTemplateUriMap = {
    'concierge':   window.CACHE_MAP[Pmp.Client.Static.TemplateDir + 'production.site.templates.concierge.js'],
    'manager':     window.CACHE_MAP[Pmp.Client.Static.TemplateDir + 'production.site.templates.manager.js'],
};
Pmp.Client.Static.Csrf = null;

Pmp.Client.Static.CsrfSafeMethod = function(method) {
    // these HTTP methods do not require CSRF protection
    return (new RegExp('^(GET|HEAD|OPTIONS|TRACE)$', 'i').test(method));
};

Pmp.Client.Static.CsrfInitialize = function(csrftoken) {
    Pmp.Client.Static.Csrf = csrftoken;

    // Ajax calls to same domain add csrftoken header
    $.ajaxSetup({
        beforeSend: function(xhr, settings) {
            if (!Pmp.Client.Static.CsrfSafeMethod(settings.type) && !this.crossDomain) {
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
            }
        }
    });

    Pmp.Client.Static.CsrfEnsureAllForms();
};

Pmp.Client.Static.CsrfEnsureAllForms = function() {
    // All forms to same domain get csrftoken added
    function _isSameOrigin(url) {
        // test that a given url is a same-origin URL
        // url could be relative or scheme relative or absolute
        var host = window.document.location.host; // host + port
        var protocol = window.document.location.protocol;
        var srOrigin = '//' + host;
        var origin = protocol + srOrigin;
        // Allow absolute or scheme relative URLs to same origin
        return (url === origin || url.slice(0, origin.length + 1) === origin + '/') ||
            (url === srOrigin || url.slice(0, srOrigin.length + 1) === srOrigin + '/') ||
            // or any other URL that isn't scheme relative or absolute i.e relative.
            !(/^(\/\/|http:|https:).*/.test(url));
    }
    $('form').each(function(i, form) {
        var formMethod = $(form).prop('method') || '';
        if (Pmp.Client.Static.CsrfSafeMethod(formMethod)) {
            return;
        }
        var formUrl = $(form).prop('action') || '';
        if (!_isSameOrigin(formUrl)) {
            return;
        }
        if (0 === $(form).find('input[name=csrftoken]').length) {
            $(form).append(Pmp.Client.Static.CsrfInput());
        }
    });
};

/*
 * Returns jQuery form input element
 */
Pmp.Client.Static.CsrfInput = function() {
    return $("<input/>", {
        type: 'hidden',
        name: 'csrftoken',
        value: Pmp.Client.Static.Csrf
    });
};
/*
 * Returns Html form input element
 */
Pmp.Client.Static.CsrfInputHtml = function() {
    //return "<input type='hidden' name='csrftoken' value='" + Pmp.Client.Static.Csrf + "' />";
    return Pmp.Client.Static.CsrfInput().outerHtml();
};

/*
  Client page Factory
  allows creation and destruction of pages via javascript funcs
*/
Pmp.Client.Factory = function() {
    this.currentTemplate = null;
    this.testRegistry = new Object();
};

/*
  Registers a page factory
  templateFunc: template to bind to
  initFunc: initializer function
  destroyFunc: destroy function
  testDataFunc (optional): test data provider function
*/
Pmp.Client.Factory.prototype.Register = function(templateFunc, initFunc, destroyFunc) {
    // make sure we have a template to append
    if (!templateFunc) throw "Factory registration requires a template";

    // assign current template
    this.currentTemplate = templateFunc;

    // only assign if provided
    if (initFunc) {
        templateFunc.init = null;
        templateFunc.init = initFunc;
    }
    if (destroyFunc) {
        templateFunc.destroy = null;
        templateFunc.destroy = destroyFunc;
    }
};

/*
  Registers test data
  url: url to bind test to
  testDataFunc: test data provider function
  queryString: optional query string for specific queries
*/
Pmp.Client.Factory.prototype.RegisterTestData = function(url, testDataFunc) {
    if (!url || url == "") throw "Test registration requires a url";
    if (!testDataFunc) throw "Test registration requires a data provider.";

    if (!this.testRegistry[url]) this.testRegistry[url] = new Object();
    this.testRegistry[url].testData = null;
    this.testRegistry[url].testData = testDataFunc;
};

/*
  Create a new test response object
  url: url
  template: template to return
*/
Pmp.Client.Factory.prototype.CreateTestResponse = function(url, template) {
    // get a test response
    var result = {
        "url": url,
        "statusCode": "200",
        "payload": {},
        "template": template
    };
    return result;
};

/*
  Initializes a page
  data: data from server
  contentID: id of area to initialize
*/
Pmp.Client.Factory.prototype.Initialize = function(contentID, data) {
    if (this.currentTemplate && this.currentTemplate.init) {
        // handle post data
        if (this.currentTemplate.post) {
            data.payload.post = this.currentTemplate.post;
            this.currentTemplate.post = null;
        }
        this.currentTemplate.init(data, contentID);
    }
    // Scroll window to top of page like regular page load
    window.scroll(0, 0);
};

/*
  Destroys current page
*/
Pmp.Client.Factory.prototype.Destroy = function() {
    if (this.currentTemplate && this.currentTemplate.destroy) {
        this.currentTemplate.destroy();
    }
};

/*
  Reset the current state of the factory
*/
Pmp.Client.Factory.prototype.Reset = function() {
    this.currentTemplate = null;
};

/*
  Get a test data provider for a key
  url: url to get test data for
  data: optional supply data to test data function
  returns test data if it exists
*/
Pmp.Client.Factory.prototype.getTestData = function(url, data) {
    if (this.testRegistry[url] && this.testRegistry[url].testData) {
        return this.testRegistry[url].testData(data);
    }
    return null;
};

/*
  Client page
  factoryObj: client factory object
*/
Pmp.Client.Page = function(factoryObj) {
    this.firstPage = true;
    this.factory = factoryObj;
    this.contentID = "";
};

Pmp.Client.Page.prototype.getContentID = function() {
    return this.contentID;
}

Pmp.Client.Page.prototype.debug = function(msg) {
    if (Pmp.Settings.DEBUG) {
        console.log(msg);
    }
}

Pmp.Client.Page.prototype.TrackPage = function(url) {
    // Google Analytics
    if (Pmp.Client.Static.PageTracker != null) {
        this.debug("Tracking: " + url);
        Pmp.Client.Static.PageTracker.push(['_trackPageview', url]);
    }
}

Pmp.Client.Page.prototype.Initialize = function(contentID, data) {

    // call template specific initializers
    this.factory.Initialize(contentID, data);

    // ajaxify current page
    if (Pmp.Client.Options.DoAjaxify) {
        this.Ajaxify(contentID);
    }

    //set content ID
    this.contentID = contentID;

    // Page tracking
    var url = null;
    if (data && data.url) {
        url = data.url
    } else {
        url = window.location.pathname + window.location.search + window.location.hash;
    }
    this.TrackPage(Pmp.Client.GetNonAjaxUrl(url));
};

Pmp.Client.Page.prototype.InitializePart = function(partID) {
    // ajaxify current page
    this.Ajaxify(this.contentID, partID);
};

Pmp.Client.Page.prototype.Render = function(contentID, templateName, data, url) {

    // First load pre-req JS imports
    if (url !== undefined && url.length > 1) {
        var key = url.substring(1); // /manager/blah/blah => manager/blah/blah
        key = key.substring(0, key.indexOf('/')); // manager/blah/blah => manager
        if (Pmp.Client.Static.JsTemplateUriMap[key] !== undefined && Pmp.Client.Static.JsTemplatesLoaded[key] === undefined) {
            Pmp.Client.Static.JsTemplatesLoaded[key] = true;
            var jsUrl = Pmp.Client.Static.JsTemplateUriMap[key];
            if (Pmp.Settings.DEBUG) {
                // Avoid caching in dev mode
                jsUrl += '?_=' + (new Date()).getTime();
            }
            jQuery.ajax({
                url: jsUrl,
                async: false,
                dataType: "script"
            });
        }
    }

    var templateFunc = null;
    var templateHtml = null;
    try {
        templateFunc = eval(templateName);
    } catch (e) {
        throw ("No registered template " + templateName);
    }
    try {
        templateHtml = templateFunc(data);
    } catch (e) {
        throw ("Invalid data for template " + templateName + " (" + e.toString() + "): " + Pmp.Utils.json_encode(data));
    }
    // remove ajaxify events
    if (!this.firstPage) {
        this.factory.Destroy();
        this.factory.Reset();
    }

    // load template
    $(contentID).html(templateHtml);

    // init page
    this.Initialize(contentID, data);
};

Pmp.Client.Page.prototype.RenderPart = function(jObj, templateName, data) {
    var templateFunc = null;
    var templateHtml = null;
    try {
        templateFunc = eval(templateName);
    } catch (e) {
        throw ("No registered template " + templateName);
    }
    try {
        templateHtml = templateFunc(data);
    } catch (e) {
        throw ("Invalid data for template " + templateName + " (" + e.toString() + "): " + Pmp.Utils.json_encode(data));
    }

    // replace jquery object with template
    var parent = jObj.parent();
    jObj.replaceWith(templateHtml);

    // ajaxify new form part
    this.InitializePart(parent);
};

Pmp.Client.GetNonAjaxUrl = function(ajax_url) {
    var idx = ajax_url.indexOf(Pmp.Client.Options.AjaxUrlPrefix);
    if (idx != -1) {
        idx += Pmp.Client.Options.AjaxUrlPrefix.length - 1;
        return ajax_url.substring(idx, ajax_url.length);
    }
    return ajax_url;
};

Pmp.Client.IsOnAjaxPage = function() {
    return window.location.toString().indexOf(Pmp.Client.Options.AjaxUrlPrefix) != -1;
};

Pmp.Client.HandleFormSubmit = function(event, contentID, formObj, targetObj, onload_callback, break_ajax) {
    event.preventDefault();
    var url = formObj.attr("action");
    var data = formObj.serialize();
    var is_post = "post" == formObj.attr("method").toLowerCase();

    // POSTs will not change the url until the data is altered
    // successfully and a redirect is returned
    if (is_post) {
        Pmp.Client.Static.Page.Load(contentID, url, data, is_post, targetObj, onload_callback, break_ajax);
    }
    // for GET, the processing will happen automatically once
    else {
        new_url = Pmp.Client.Options.AjaxUrlPrefix + url.substring(1) + '?' + decodeURIComponent(data);
        if (Pmp.Client.IsOnAjaxPage()) {
            // if going from ajax page
            Pmp.Client.ChangeUrl(new_url)
            Pmp.Client.Static.Page.Load(contentID, url, data, is_post, undefined, onload_callback, break_ajax);
        } else {
            // if going from regular page
            Pmp.Client.ChangeUrl(new_url);
        }
    }
};

Pmp.Client.Page.prototype.Navigate = function(contentID, relative_url, onload_callback, break_ajax) {
    if (!Pmp.Client.Options.DoAjaxify) {
      this.ShowAjaxSpinner();
      break_ajax = true;
    }
    var pendingRequests = Pmp.Client.Static.PendingRequests;
    Pmp.Client.Static.PendingRequests = [];
    for (var i = 0; i < pendingRequests.length; i++) {
        var xhr = pendingRequests[i];
        if (xhr && xhr.readyState != 4) {
            xhr.abort();
        }
    }

    if (relative_url === undefined) {
        return;
    }
    var url = Pmp.Client.Options.AjaxUrlPrefix + relative_url.substring(1);

    if (Pmp.Client.IsOnAjaxPage()) {
        // if going from ajax page
        Pmp.Client.ChangeUrl(url);
        Pmp.Client.Static.Page.Load(contentID, relative_url, undefined, undefined, undefined, onload_callback, break_ajax);
    } else {
        // if going from regular page
        url = break_ajax? relative_url:url;
        Pmp.Client.ChangeUrl(url);
    }
}

Pmp.Client.Page.prototype.Ajaxify = function(contentID, partID) {
    if (!this.firstPage) {
        return;
    }
    // page needs to be accessible
    var page = this;
    var objString = "." + Pmp.Client.Options.AjaxClassName
    $(document).on('submit', objString, function (event) {
        var jObj = $(event.target)
        Pmp.Client.HandleFormSubmit(event, contentID, jObj, jObj)
    })
    $(document).on('click', objString, function (event) {
        var jObj = $(event.target)
        if (!jObj.hasClass('ajaxify')) {
            return
        }
        event.preventDefault()
        var raw_url = jObj.attr("href")
        var post_hook = jObj.attr("ajaxify_post_hook")
        var post_hook_fn = function () {
            if (post_hook) {
                try {
                    eval(post_hook)
                } catch (e) {
                    throw ("Invalid post hook for link " + raw_url)
                }
            }
        }

        page.Navigate(contentID, raw_url, post_hook_fn)
    })
};

Pmp.Client.Page.prototype.AddLiveHandler = function(selector, eventType, fn, no_debug) {
    if (!no_debug) {
        this.debug("Adding live click handler: " + selector + " / " + eventType);
    }
    var live_event_handlers = Pmp.Client.Static.LiveEventHandlers;

    if (!live_event_handlers[selector]) {
        live_event_handlers[selector] = []
    }
    var eventTypeList = live_event_handlers[selector];
    eventTypeList[eventTypeList.length] = eventType;

    $('body').on(eventType, selector, fn);
};

/*
  Unajaxfies a page by removing handlers to ajax methods
  id = id of div tag to unajaxify, if none specified does everything
*/
Pmp.Client.Page.prototype.Unajaxify = function(contentID) {
    // Remove all live event handlers
    var live_event_handlers = Pmp.Client.Static.LiveEventHandlers;
    for (var selector in live_event_handlers) {
        var eventTypeList = live_event_handlers[selector];
        $(eventTypeList).each(function(j, eventType) {
            $('body').off(eventType, selector);
        });

        this.debug("Removing live click handlers for: " + selector);
        delete live_event_handlers[selector];
    }
};

Pmp.Client.Page.prototype.ShowAjaxSpinner = function(callbackFn) {
    this.debug("Showing spinner");
    var spinner = $('#ajax-spinner');

    ($('manager').length === 0)?
        spinner.removeClass('wide-nav'):spinner.addClass('wide-nav');
    // Trick jquery into showing in all browsers by first showing,
    // then animating on 1-millisecond no-op before continuing to allow
    // re-draw
    spinner.show();
    spinner.animate({
        opacity: 1
    }, 1, callbackFn);
};

Pmp.Client.Page.prototype.HideAjaxSpinner = function(doStop) {
    this.debug("Hiding spinner");
    if (doStop) {
        $('#ajax-spinner').stop();
    }
    $('#ajax-spinner').hide();
};

Pmp.Client.Page.prototype.Load = function Load(contentID, url, data, is_post, jObj, onload_callback, break_ajax) {
    // Show Loading animation if the spinner element is present.
    // Otherwise, just do the callback and get some data.
    var self = this;

    if($('#ajax-spinner').length === 0) {
        self.doLoad(contentID, url, data, is_post, jObj, onload_callback, break_ajax);
    } else {
        this.ShowAjaxSpinner(function() {
            self.doLoad(contentID, url, data, is_post, jObj, onload_callback, break_ajax);
        });
    }
};

Pmp.Client.Page.prototype.doLoad = function(contentID, url, data, is_post, jObj, onload_callback, break_ajax) {
    var page = this;
    this.debug("Loading " + url);
    var onCompletedFn = function(payload) {
        Pmp.Client.Static.CsrfEnsureAllForms();
        page.HideAjaxSpinner();
        if (onload_callback) {
            onload_callback(payload);
        }
    };

    var ajaxResponseFn = function(response) {
        // This giant exception handler makes it excruciatingly difficult to track
        // down many kinds of errors. Should be ditched.
        try {
            if (!response) {
                alert("SEVENROOMS is having trouble reaching the server.  Please wait a few moments and try refreshing your browser window.");
                throw ("Null response received");
            }

            // If redirect, just load the content and return
            if (Pmp.Client.Utils.isRedirect(response)) {

                var prefix = Pmp.Client.Options.AjaxUrlPrefix;
                if (response.payload.breakAjax || break_ajax === true) {
                    prefix = '/';
                }
                var relative_redirect_url = Pmp.Client._getRelativeUrlFromAbsoluteUrl(response.payload.redirect_url);
                //alert('orig: ' + response.payload.redirect_url + ', rel: ' + relative_redirect_url + ', of 0: ' + relative_redirect_url[0]);
                if (relative_redirect_url[0] = '/') {
                	relative_redirect_url = relative_redirect_url.substring(1);
                }
                var url_redirect = prefix + relative_redirect_url;
                if (response.payload.breakAjax) {
                    window.location = url_redirect;
                    throw "SEVENROOMS is undergoing maintenance.";
                } else if (window.location.toString().indexOf(Pmp.Client.Options.AjaxUrlPrefix) != -1) {
                    // if going from ajax page
                    Pmp.Client.ChangeUrl(url_redirect);
                    page.doLoad(contentID, response.payload.redirect_url, undefined, undefined, undefined, onload_callback);
                } else {
                    // if going from regular page
                    Pmp.Client.ChangeUrl(url_redirect);
                }
                return;
            }

            // If app error code, render the form object again with error data
            if (Pmp.Client.Utils.isRenderPart(response)) {
                // TODO: if response has a div to render, make jObj equal to that div...
                if (jObj) {
                    page.RenderPart(jObj, response.template, response.payload);
                    onCompletedFn(response.payload);
                    return;
                } else {
                    throw ("Form submission returned an error and jQuery form object is missing.\r\n");
                }
            }

            if (!is_post && response.payload.settings.VERSION != Pmp.Client.Static.SiteVersion) {
                // Then just do a page refresh to get the new site version
                var absolute_url = Pmp.Client._getAbsoluteUrl(url);
                //console.log('New site version loading at ' + absolute_url);
                window.location = absolute_url;
                return;
            }

            // close all modal windows
            jQuery.colorbox.close();

            // Unajaxify before rendering content
            if (!page.firstPage) {
                page.Unajaxify(contentID);
            }

            // init page
            try {
                page.Render(contentID, response.template, response.payload, response.url);
            } catch (err) {
                page.debug("Render Error: " + err.toString());
                if (Pmp.Settings.DEBUG) {
                    alert('[DEBUG] Render Error (check console)')
                    throw err;
                } else {
                    window.location = Pmp.Settings.MEDIA_URL + 'html/error/500.htm';
                }
            }

            // No longer the first page
            page.firstPage = false;
            onCompletedFn(response.payload);
        } catch (err) {
            page.HideAjaxSpinner(true);
            // Re-enable pending buttons to allow re-tries...
            $('.gold-button.disabled.pending').removeClass('disabled pending');
            page.debug("onLoad Error: " + err.toString());
        }
    };

    try {
        if (!url) {
            url = Pmp.Client.getCurrentPage();
            data = Pmp.Client.QueryToJSON($.address.queryString());
        }
        // JSON response object
        Pmp.Client.LoadAjaxData(url, data, is_post, ajaxResponseFn)

    } catch (err) {
        page.HideAjaxSpinner(true);
        // Re-enable pending buttons to allow re-tries...
        $('.gold-button.disabled.pending').removeClass('disabled pending');
        this.debug("onLoad Error: " + err.toString());
    }
};

/*
    Create a new page
*/
Pmp.Client.CreatePage = function() {
    Pmp.Client.Static.Page = new Pmp.Client.Page(Pmp.Client.Static.Factory);
};

Pmp.Client.IsValidPendingRequest = function(jqXHR) {
    if (jqXHR) {
        var idx = Pmp.Client.Static.PendingRequests.indexOf(jqXHR); // Find the index
        if (idx !== undefined && idx !== -1) {
            Pmp.Client.Static.PendingRequests.splice(idx, 1);
        } else {
            return false;
        }
    }
    return true;
};

/*
  Load ajax data
  url: url to load
*/
Pmp.Client.LoadAjaxData = function(url, data, is_post, asyncFn) {
    var param_delimiter = '?';
    if (/\?/.test(url)) {
        // Change to ampersand if url already contains GET params
        var param_delimiter = '&';
    }

    async_flag = false;
    if (asyncFn) {
        async_flag = true;
    }

    var handleResponse = function(data, textStatus, jqXHR) {
        if (Pmp.Client.IsValidPendingRequest(jqXHR) === false) {
            return;
        }
        if ((data == '') || (data == null) || (!jqXHR)) {
            asyncFn(null);
        } else {
            // alert if there is a warning in response
            if (data.payload.redirect_url) {
                urlParams = new URLSearchParams(data.payload.redirect_url);
                if (urlParams.get('warning')) {
                    alert(`Warning: ${urlParams.get('warning')}`);
                }
            }

            // check if there is maintenance, and if so, redirect
            if (jqXHR.responseText.indexOf("_redirect_for_maintenance") != -1) {
                responseJson = {
                    'statusCode': '302',
                    'payload': {
                        'redirect_url': '',
                        'breakAjax': true
                    }
                }
                data = responseJson;
            }

            if (asyncFn) {
                asyncFn(data);
            }
        }
    };

    var handleError = function(jqXHR, textStatus) {
        if (jqXHR.status === 400) {
            alert(jqXHR.responseText);
            $('#ajax-spinner').hide();
            return;
        }
        if (Pmp.Client.IsValidPendingRequest(jqXHR) === false) {
            return;
        }
        asyncFn(null);
    };

    var request = {
        'async': async_flag,
        'url': url + param_delimiter + '_=' + (new Date()).getTime(), // Force IE to respect the no-cache
        'cache': false,
        'contentType': 'application/x-www-form-urlencoded', //'application/json',
        'success': handleResponse,
        'error': handleError
    };
    request['type'] = is_post ? "POST" : "GET";
    if (data) request['data'] = data;

    // Report activity to avoid unnecessary keepalive requests
    Pmp.Main.Timeout.reportServerCall();

    // make request
    var xhr = jQuery.ajax(request);
    Pmp.Client.Static.PendingRequests.push(xhr);
};

Pmp.Client.LoadAjaxForm = function(formObj, ajaxResponseFn) {
    var form_url = formObj.attr("action");
    var form_data = formObj.serialize();
    var is_post = "post" == formObj.attr("method").toLowerCase();
    return Pmp.Client.LoadAjaxData(form_url, form_data, is_post, ajaxResponseFn);
};

Pmp.Client.AsyncPost = function(formObj, responseFn) {
    var form_url = formObj.attr("action");
    var form_data = formObj.serialize();
    var is_post = "post" == formObj.attr("method").toLowerCase();

    if (is_post) {
        var handleResponse = function(data, textStatus, jqXHR) {
            if (Pmp.Client.IsValidPendingRequest(jqXHR) === false) {
                return;
            }
            if (data == '') {
                alert('SEVENROOMS is having trouble saving your changes. Please check your internet connection.');
            } else {
                if (responseFn) {
                    responseFn(data);
                }
            }
        };

        var handleError = function(jqXHR, textStatus) {
            if (Pmp.Client.IsValidPendingRequest(jqXHR) === false) {
                return;
            }
            alert('SEVENROOMS is having trouble saving your changes. Please check your internet connection.');
        };

        var request = {
            async: true,
            url: form_url,
            type: 'POST',
            cache: false,
            data: form_data,
            success: handleResponse,
            error: handleError
        };

        // Report activity to avoid unnecessary keepalive requests
        Pmp.Main.Timeout.reportServerCall();

        //make request
        var xhr = jQuery.ajax(request);
        Pmp.Client.Static.PendingRequests.push(xhr);

    } else {
        throw ("Trying to async post with a GET form. \r\n");
    }
};


Pmp.Client.AsyncGet = function(url, responseFn, queue) {

    var handleResponseQueue = function(data, textStatus, jqXHR) {
        if (data == '') {
            console.log('Erroneous get');
        } else {
            if (responseFn) {
                responseFn(data);
            }
        }
    };

    var handleResponseAjax = function(data, textStatus, jqXHR) {
        if (Pmp.Client.IsValidPendingRequest(jqXHR) === false) {
            return;
        }
        if (data == '') {
            console.log('Erroneous get');
        } else {
            if (responseFn) {
                responseFn(data);
            }
        }
    };

    var handleError = function(jqXHR, textStatus) {
        if (Pmp.Client.IsValidPendingRequest(jqXHR) === false) {
            return;
        }
    };

    var request = {
        global: false,
        async: true,
        url: url,
        type: 'GET',
        cache: false,
        contentType: 'application/json',
        success: (queue) ? handleResponseQueue : handleResponseAjax,
        error: handleError
    };

    // Report activity to avoid unnecessary keepalive requests
    Pmp.Main.Timeout.reportServerCall();

    //make request
    if( queue ) {
      jQuery.ajaxq(queue, request);
    } else {
      var xhr = jQuery.ajax(request);
      Pmp.Client.Static.PendingRequests.push(xhr);
    }
};


/*
  convert a query string to a json object
  query: query string
*/
Pmp.Client.QueryToJSON = function(query) {
    var obj = new Object();
    var names = $.address.parameterNames();
    for (var i in names)
    obj[names[i]] = $.address.parameter(names[i]);
    return obj;
};

/*
  get a response from the cache
  key: has key for response storage
  data: response object
*/
Pmp.Client.getCacheResponse = function(key) {
    // TODO: check cache for url
    return null;
};

/*
  cache a response if cacheable
  key: hash key for response storage
  data: response object
*/
Pmp.Client.CacheResponse = function(key, data) {
    // TODO: cache response in local storage according to hash key
    // TODO: check if data expiration isn't immediate
};

/*
  Registers an address handler properly
*/

Pmp.Client.RegisterAddressHandler = function(func) {
    // on address changes run the func, only if its not the first time
    $.address.externalChange(function(event) {
        if (Pmp.Client.Static.EnableAddressHandling && (Pmp.Client.Static.AddressChangeCount > 0)) {
            func();
        }
        Pmp.Client.Static.AddressChangeCount++;
        Pmp.Client.Static.EnableAddressHandling = true;
    });
};

/*
  changes window location
*/

Pmp.Client.ChangeUrl = function (url) {
    try {
      window.stop();
    }
    catch(e) {}

    var prev_url = window.location.href;
    if (prev_url.indexOf(url, prev_url.length - url.length) === -1) {
        Pmp.Client.Static.EnableAddressHandling = false;
    }

    var new_url = Pmp.Client._getAbsoluteUrl(url);
    window.location = new_url;
};

Pmp.Client._getAbsoluteUrl = function(url_path) {
    var loc = window.location;
    var url = "" + loc.protocol + "//" + loc.host + url_path;
    return url;
};

Pmp.Client._getRelativeUrlFromAbsoluteUrl = function(url_path) {
	is_absolute = /^https?:\/\//i
	if (is_absolute.test(url_path)) {
		return '/' + url_path.split("/").slice(3).join("/")
	}
	return url_path
}


Pmp.Client.getRelativeCurrentPageAjaxified = function() {
	if (Pmp.Client.IsOnAjaxPage()) {
		return Pmp.Client.getCurrentPage();
	}
	return window.location.pathname;
}

/*
  Gets the current URL without the base
*/
Pmp.Client.getCurrentPage = function() {
	var addy = $.address.path();
    return (addy != "") ? addy : "/";
};

/*
    Given a response object, is it a redirect code?
*/
Pmp.Client.Utils.isRedirect = function(response) {
    return response.statusCode == '301' || response.statusCode == '302';
};

/*
    Given a response object, should I render a portion of the page?
*/
Pmp.Client.Utils.isRenderPart = function(response) {
    // 202 = error code
    // 201 = render part
    return response.statusCode == '202' || response.statusCode == '201';
};

/*
  Static initializations
*/
Pmp.Client.Static.Factory = new Pmp.Client.Factory();
