/** 
 * Simple cookie interface to document.cookie in order to get and
 * set cookies.
 */
var CookieManager = (function() {
  var _parseCookies = function(cookie_string) {
    var cookies = cookie_string.split('; ');
    var c = {};
    for(var i=0; i < cookies.length; i++) {
      var parts = cookies[i].split('=');
      c[parts[0]] = parts[1];
    }
    return c;
  };

  /**
   * Encode an object as the value for document.cookie
   */
  var _encodeCookies = function(cookies) {
    var cookie_strings = [];
    for(var c in cookies){
      cookie_strings.push(c + '=' + cookies[c]);
    }
    return cookie_strings.join('; ');
  };

  var _set = function(name, val) {
    var cookies = _parseCookies(document.cookie);
    cookies[name] = val;
    return cookies;
  };

  var _get = function(name){
    var cookies = _getAll();
    return cookies[name];
  };

  var _getAll = function() {
    return _parseCookies(document.cookie);
  };
  
  return {
    parseCookies: _parseCookies,
    encodeCookies: _encodeCookies,
    set: _set,
    get: _get,
    getAll: _getAll
  };
  
})();
