//Put chat related stuff in here, start organizing
var Chatter = {

  bottom: 0,
  num_messages: 0,

  init: function()
  {
    var that = this;

    this._ignore_activity = false;
    this.$interface = $('#chat-interface');
    this.$input = $('#chat-input');
    this.$attacher = $('#attach-file');
    this.$send = $('#chat-send');
    this.$area = $('#chat-area');
    this.$chats = $('#chats');
    this.$conversation_id = $('#chat-conversation_id');
    this.$full_name = $('#chat-full_name');
    this.$author_id = $('#chat-author_id');
    this.$image_key = $('#chat-image_key');
    this.$base_url = $('#chat-base_url');
    this.$chat_counter = $('#chat-counter');
    this.$has_chat_recipient_email = $('#chat-has_chat_recipient_email');

    this.$send_msg = $('#chat-send-message');
    this.$close_message = $('#close-arrow-link');
    this.$new_note = $('#chat-new-note');
    this.$new_message = $('#chat-new-message');

    this.$transaction_type = $('#chat-transaction_type');
    this.$date_formatted = $('#chat-date_formatted');
    this.$entity_id = $('#chat-entity_id');


    this.$attachments_id = $('#chat-attachments_id');

    this.$area.scrollTop(999999999); // safe to say it won't come to this

    // allow submission only in the case that either text or attachments exist
    this.$input.on('keyup', function() {
      if (!that.$input.val() && !Attachments.hasUploadData()){
        if (!that.$send.hasClass('disabled')) {
          that.$send.addClass('disabled');
        }
      } else {
        if (that.$send.hasClass('disabled')) {
          that.$send.removeClass('disabled');
        }
      }
    });
    // update disabled status
    this.validate();

    this.$send.on('click', function() {

      if (that.$send.hasClass('disabled')) { return false; }

      //if (!that.$input.val()) { return false; }

    	// check for attachments
    	if (Attachments.hasUploadData()) {
    		var data = Attachments.getLastUploadData();

        var sample = data[0];
        var attachment_id = data[0].attachment_group_key;
        var is_internal = data[0].is_internal;

        var names = [];
        var prepend = ' ';
        for (i=0; i<data.length; i++) {
          names.push(data[i].filename);
        }
        if (names.length > 1) {
          prepend = 's ';
        }
        names = prepend + names.join(', ');

        $.ajax({
          url: '/attachment/' + attachment_id + '/register/' + is_internal,
          method: 'post',
          async: false,
          data: {
            "attachment_data": JSON.stringify(data)
          }
        }).done(function(response) {
          if (response.success) {
            that.sendchat(undefined,'added attachment' + names, 'ACTIVITY','ATTACHMENT',undefined,data);
          } else {
            window.SvrManager.UserNotifications.postErrorMessage(response.error_message || 'Attachment failure'); // exported in dayview app.jsx
          }
        });

    	}

    	that.sendchat();
    	return false;
    });

    this.$activity = $('#tab-activity');
    $('#nav-activity').on('click', $.proxy(this.loadactivity, this));

    this.$new_message.on('click', $.proxy(this.load_new_message, this));
    this.$new_note.on('click', $.proxy(this.load_new_note, this));
    this.$close_message.on('click', $.proxy(this.close_message,this));
    this.$attachment_display = $('.attachment-filename-display');
    this.call_in_progress = [];
    this.init_temp();

    // Fetch data the first time
    this.fetch(true);
  },


  init_temp : function() {
	  var typeselect= function(e) {
	    $('.type.chat').removeClass('selected');
	    $(e.target).parent().addClass('selected');
	  }
	  $('#type-internal').on('click', function(e) {
	    typeselect(e);
	    $('#chat-interface').addClass('internal');
	    return false
	  });
	  $('#type-client').on('click', function(e) {
	    typeselect(e);
	    $('#chat-interface').removeClass('internal');
	    return false
	  });
  },

  validate : function () {
    // update disabled status
    this.$input.keyup();
  },

  ignore_activity : function () {
	this._ignore_activity = true;
  },

  load_new_message : function () {
    if (this.$interface.hasClass('internal')) {
      this.$interface.removeClass('internal');
      this.$send_msg.removeClass('lock');
      this.clearmessage();
    }
  	this.$send_msg.removeClass('lock');
  	Attachments.clearUploadCache();
  	this.$attachment_display.html('');
  	this.$send_msg.show();
  	this.$send_msg.switchClass('contracted', 'expanded', 300, "linear");
  	this.validate();
  },

  load_new_note : function () {
    if (!this.$interface.hasClass('internal')) {
      this.$interface.addClass('internal');
      this.$send_msg.addClass('lock');
      this.clearmessage();
    }
  	Attachments.clearUploadCache();
  	this.$attachment_display.html('');
  	this.$send_msg.show();
  	this.$send_msg.switchClass('contracted', 'expanded', 300, "linear");
  	this.validate();
  },

  close_message : function () {
    Attachments.clearUploadCache();
    this.$attachment_display.html('');
    this.$send_msg.switchClass('expanded', 'contracted', 300, "linear");
    this.$send_msg.hide();
    this.validate();
  },

  startTimer : function () {
    // start looping indefinitely
    var that = this;
	  if (this._ticktock) {
	    return;
	  }

    this._ticktock = setInterval(function() {that.fetch()}, 5000);

  },

  fetch: function(initial_load)
  {
    if (initial_load) {
      this.$input.val('');
      this.$chats.empty();
      this.$chat_counter.html('');
      this.$chat_counter.hide();
    }
    var that = this;
    var conversation_id = this.$conversation_id.val();

    if (!conversation_id) {
      return;
    }

    // make sure there is an open chat
    if (conversation_id.length < 1) {
    	return;
    }

    // set uploader handler url
    var is_internal = (this.$interface.hasClass('internal')) ? '1' : '0';
    $('#chat-uploader').attr('rurl', '/attachment/' + this.$attachments_id.val() + '/add/' + is_internal);

    // generate timedelta here if need be
    var seconds_ago = 365*24*60*60; //forever basically
    // AJAX
    $.ajax({
      global: false,
      url: that.$base_url.val() + '/message/' + conversation_id,
      data: { 'seconds_ago': seconds_ago },
      beforeSend: function(xhr) {
        if (that.call_in_progress[conversation_id]) {
          xhr.abort();
        } else {
          that.call_in_progress[conversation_id] = true
        }
      },
      success: function(response) {

        that.call_in_progress[conversation_id] = false;
        // If by now the id has changed, skip
    	  if (conversation_id !== that.$conversation_id.val()) { return; }

      	var messages = response.payload.messages;

        if (messages.length === that.num_messages) { return; }
        that.num_messages = messages.length;

      	that.$chats.empty();
      	that.$chat_counter.html('');
      	that.$chat_counter.hide();

      	var user_id = response.payload.request.user.id;
      	if (messages) {
      		for (var i=0; i < messages.length; i++) {
          		var msg = messages[i];

          		// visibility is a list of all possible lists
          		var visibility = 'all';
          		if (msg.visibility[0] === 'INTERNAL') {
          			visibility = 'internal';
          		}

          		// ignore acitivity messages sometimes
          		is_public_activity = msg.category === 'ACTIVITY' && visibility === 'all';
          		if (that._ignore_activity && is_public_activity && !msg.status) {
          			continue;
          		}

          		// internal or exteral
          		var in_or_out = 'in';
          		var img_key = null;
          		if (msg.author_id === user_id) {
          			in_or_out = 'out';
          			img_key = that.$image_key.val();
          		}


          		that.addchat(msg.author_name, msg.author_photo_key, msg.text, msg.created_humanized,
          				in_or_out, visibility, true, msg.system_text, msg.status, true);
          	}
      	}
      	that.orient();
      },
      error: function() {
        that.call_in_progress[conversation_id] = false
      }
    });
  },

  orient: function(force)
  {
    var view = this.$area.height()
      , chat = this.$chats.height()
      , scrolltarget = chat - view + 10;
    if (force) {
    	this.$area.scrollTop(scrolltarget);
    	return;
    }
    var currpos = this.$area.scrollTop();
    var minBound = currpos > (scrolltarget*.05);
    var maxBound = currpos < (scrolltarget*.95);
    if (minBound && maxBound) {
    	return; //prevent orient if you're scrolling back in time
    }
    this.$area.animate({ scrollTop: scrolltarget }, 500, "easeOutBounce");
  },

  sendchat: function(msg,system_msg,category,status,is_internal,attachments)
  {
    var type = 'out';
    var name = this.$full_name.val(); // TODO This needs to change, obviously
    var image_key = this.$image_key.val(); // TODO This needs to change, obviously
    var content = this.$input.val();

    if (msg !== undefined) {
      content = msg;
    }
    var conversation_id = this.$conversation_id.val();
    var visibility = (this.$interface.hasClass('internal')) ? 'i' : 'a';
    if (is_internal !== undefined) {
      visibility = (is_internal) ? 'i' : 'a';
    }
    var has_chat_recipient_email = this.$has_chat_recipient_email.val();

    var transaction_type = this.$transaction_type.val();
    var date_formatted = this.$date_formatted.val();
    var entity_id = this.$entity_id.val();

    if ((!$.trim(content)) && (msg === undefined) && (!attachments)) {
      return;
    }

    var time = dateFormat(new Date(), 'mmm d h:MMTT').toLowerCase();
    var that = this;

    if (has_chat_recipient_email === 'false' && visibility === 'a') {
      Interface._alert('Please add an email to send messages');
      this.close_message();
      return false
    }


    // AJAX send dat chat
    $.ajax({
      global: false,
      type: 'POST',
      url: that.$base_url.val() + '/message/create',
      data: {
        'conversation_id':conversation_id,
        'message':content,
        'system_message' : (system_msg === undefined) ? null : system_msg,
        'category': (category === undefined) ? null : category,
        'status' : (status === undefined) ? null : status,
        'attachments' : (attachments === undefined) ? null : JSON.stringify(attachments),
        'visibility':visibility,
        'transaction_type' : transaction_type,
        'date_formatted' : date_formatted,
        'entity_id' : entity_id},
      success: function(response) {
        // add yourself as a follower
        var userid = that.$author_id.val();

        // don't add follower or assigned if internal note is added
        if (is_internal) {
          return;
        }

        if (Assigned.isRequest()) {
          if (!Assigned.isSet()) {
            Assigned.replace(userid);
          } else if (Assigned.getUserId() !== userid) {
            that.addFollower(transaction_type, userid);
          }
        } else {
          that.addFollower(transaction_type, userid);
        }
        CustomerSuccessTracker.trackRequestInbox('Respond to Request')
      }
    });

    // Want the interface to appear snappy, especially
    // when I hide global loaders for chats.
    this.$input.val('');

    this.addchat(name, image_key, content, time, type,undefined,undefined,system_msg,status);

    this.close_message();

    return false;
  },

  addFollower : function (transactionType, userid) {
    // TODO: this sucks. would prefer a callback method
    if (transactionType === 'Request') {
      RequestSlideout.followers.add(userid);
    } else {
      ReservationSlideOut.followers.add(userid);
    }
  },

  addchat: function(_name, _image_key, _content, _time,
                    type, visibility, skip_orient, system_message,
                    _status, prepend) {
    var chat = $('<div/>').addClass('chat-line ' + type)
      , thumb = $('<p/>').addClass('thumb')
      , img = $('<img/>')
      , message = $('<p/>').addClass('message')
      , name = $('<strong/>')
      , content = $('<span/>')
      , time = $('<em/>');

    check = _name.split(' ');
    let sys_message = (check[0] === "Email" || check[0] === "SMS" || check[0] === "Automated")
    // make initials for users sans system generated messages
    if( check.length === 2 && !sys_message) {
      check[1] = check[1].substr(0,1);
      _name = check.join(' ');
    }

    name.sext(_name);

    if (_status && _status.trim()) {
      // Status can be a comma separated list of statuses
      var statusList = _status.split(',');
      for (var i = 0; i < statusList.length; i++) {
        var tag = $('<span/>').addClass('tag').addClass(statusList[i].toLowerCase()).sext(statusList[i]);
        message.append(tag);
      }
      message.append('<br/>');
    }

    system = '';
    if (system_message !== null) {
      system = $('<span/>').addClass('system').sext(system_message);
    }

    time.sext(_time);
    if (_content) {
      content.html($.nl2br(_.escape(_content)));
    }
    if (_image_key) {
      img.attr('src', '/.h/download/' + _image_key);
    }
    thumb.append(img);


    var is_internal;
    if (visibility === undefined) {
      is_internal = this.$interface.hasClass('internal');
    } else {
      is_internal = visibility === 'internal';
    }
    if( is_internal ) {
      chat.addClass('internal');
    } else {
      chat.append(thumb);
    }

    message.append(name, system, '<br/>', content, time);
    chat.append(message);

    if (prepend) {
      this.$chats.prepend(chat);
    } else {
      this.$chats.append(chat);
    }

    this.$chat_counter.sext($('.chat-line').length);
    if( $('.chat-line').length ) {
      this.$chat_counter.show();
    }

    // Maybe make this optional, it'll cause jumps on load
    if (!skip_orient) {
    	this.orient(true);
    }
  },

  loadactivity: function()
  {
    var that = this;
    $.ajax({
      url: this.$base_url.val() + '/activity/' + that.$conversation_id.val(),
      global: false,
      success: function(response) {
        that.$activity.empty();
        var activity = response.payload.activity;
        if (activity) {
          var user_id = response.payload.request.user.id;
          for (var i=0; i < activity.length; i++) {
            var action = activity[i];
            var in_or_out = 'in';
            var img_key = null;
            if (action.author_id === user_id) {
              in_or_out = 'out';
              img_key = that.$image_key.val();
            }

            that.addactivity(action.author_name, action.created_humanized, action.system_text, action.status, action.device);
          }
        }
      }
    });
  },

  addactivity: function(_name, _time, _system_message, _status, _device)
  {

    function firstToLowerCase( str ) {
    	return str.substr(0, 1).toLowerCase() + str.substr(1);
    }

    var statusLower = (_status) ? _status.toLowerCase() : '';
    var action = $('<p/>').addClass('activity ' + statusLower)
      , dot = $('<span/>').addClass('dot').html('&middot;')
      , message = $('<span/>').addClass('info').html('<strong>' + _name + '</strong> ' + firstToLowerCase(_system_message))
      , device_text = !_.isEmpty(_device) ? '[' + _device  + '] ' : ''
      , time = $('<em/>').sext(device_text + _time);

    action.append(dot, message, time);

    this.$activity.append(action);
  },

  clearmessage: function() {
    this.$input.val('');
  }
}
